import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Workout 20.2`}</strong>{`\\
Complete as many rounds as possible in 20 minutes of:`}</p>
    <p>{`4 dumbbell thrusters\\
6 toes-to-bars\\
24 double-unders`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
35-lb. dumbbells
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
50-lb. dumbbells`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be judging Open wod 20.2 today during all classes with our
evening class times (4:00-7:00pm) being our Friday Night Throwdown!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      